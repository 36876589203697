<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <UsersList />
  </Layout>
</template>

<script>
import Layout from '@/views/Layout'
import UsersList from '@/components/Users/UsersList'

export default {
  name: 'StoresIndexView',
  components: {
    Layout,
    UsersList
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Cadastros',
        to: { name: 'RegistersIndex' }
      },
      {
        text: 'Usuários',
        to: { name: 'UsersIndex' },
        active: true
      }
    ]
  }
}
</script>
