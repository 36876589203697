<template>
  <span>
    {{ userStoreNames }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { join } from 'lodash/fp'

export default {
  name: 'UserStoreNames',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId']),
    userStoreNames() {
      return join(
        ' | ',
        this.user.userStores.map((item) => item.store.name)
      )
    }
  }
}
</script>
