<template>
  <span>
    {{ userStoreRoleNames }}
  </span>
</template>

<script>
import { mapGetters } from 'vuex'
import { join } from 'lodash/fp'
import { ROLES } from '@/constants'

export default {
  name: 'UserStoreRoleNames',
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('store', ['currentStoreId']),
    userStoreRoleNames() {
      const roles = Object.values(ROLES)
      return join(
        ' | ',
        this.user.userStores.map(
          (item) => roles.find(({ value }) => value === item.role).text
        )
      )
    }
  }
}
</script>
