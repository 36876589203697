<template>
  <div>
    <b-button-toolbar key-nav class="mb-3">
      <b-button variant="primary" size="sm" :to="{ name: 'UserRegister' }">
        <span class="fas fa-plus mr-1" /> Cadastrar Usuário
      </b-button>
    </b-button-toolbar>

    <b-alert v-if="error" show variant="danger">
      <span class="fas fa-exclamation-triangle" /> {{ error }}
    </b-alert>

    <b-table
      ref="usersList"
      :fields="fields"
      :items="provider"
      striped
      hover
      :busy="isBusy"
    >
      <div slot="empty" class="text-center">Nenhum usuário cadastrado</div>
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="getColumnStyle(field.key)"
        />
      </template>
      <template #cell(store)="{ item: user }">
        <UserStoreNames :user="user" />
      </template>
      <template #cell(status)="{ value: status }">
        <Status :value="status" />
      </template>
      <template #cell(role)="{ item: user }">
        <UserStoreRoleNames :user="user" />
      </template>
      <template #cell(actions)="{ item }">
        <BaseListActions
          :item="item"
          @edit="onEdit"
          @remove="onRemove"
          @updateStatus="onUpdateStatus"
        />
      </template>
    </b-table>
  </div>
</template>

<script>
import services from '@/services'
import withStatusAction from '@/mixins/withStatusAction'
import Status from '@/components/Status'
import UserStoreNames from '@/components/UserStoreNames'
import UserStoreRoleNames from '@/components/UserStoreRoleNames'
import BaseListActions from '@/components/BaseListActions'
import { parseHTTPError } from '@/helpers/errors'

export default {
  name: 'UsersList',
  components: {
    Status,
    UserStoreRoleNames,
    UserStoreNames,
    BaseListActions
  },
  mixins: [withStatusAction(services.users.updateUser)],
  data() {
    return {
      isBusy: false,
      error: null
    }
  },
  computed: {
    fields: () => [
      {
        key: 'name',
        label: 'Nome'
      },
      {
        key: 'email',
        label: 'Email'
      },
      {
        key: 'store',
        label: 'Loja'
      },
      {
        key: 'status',
        label: 'Status'
      },
      {
        key: 'role',
        label: 'Tipo'
      },
      {
        key: 'actions',
        label: 'Ações'
      }
    ]
  },
  methods: {
    async provider() {
      this.isBusy = true
      try {
        return await services.users.fetchUsers()
      } catch (error) {
        this.error = parseHTTPError(error)
        return []
      } finally {
        this.isBusy = false
      }
    },
    onEdit(user) {
      const { id } = user

      this.$router.push({
        name: 'UserRegister',
        params: { id }
      })
    },
    async onRemove(user) {
      const message = `Deseja realmente remover o usuário ${user.name}?`
      const result = await this.$bvModal.msgBoxConfirm(message)

      if (result) {
        await services.users.removeUser(user.id)
        this.$refs.usersList.refresh()
        this.$root.$toast.success('Usuário removido com sucesso')
      }
    },
    async onUpdateStatus(user) {
      await this.toggleStatus(user)
      this.$refs.usersList.refresh()
    },
    getColumnStyle(field) {
      return { width: field === 'actions' ? '1%' : '' }
    }
  }
}
</script>
